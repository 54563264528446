import { jsPDF } from 'jspdf'
import * as NordicNarrow from './NordicNarrow-normal'

type ArtworkItem = {
    title: string,
    dateTime: string,
    b64: string
}

const container = document.getElementById('artwork-container') as HTMLElement
const header = document.getElementById('museum-name') as HTMLElement

window.onload = () => {
    header.innerHTML = capitalizeString(GetURLParameter())
}

function generateList(item: ArtworkItem): void {
    let section = document.createElement('div')
    let columnThumbnail = document.createElement('div')
    let columnDetails = document.createElement('div')
    let thumbnailButton = document.createElement('button')
    let thumbnailImage = document.createElement('img')
    let details = document.createElement('ul')
    let detailsTitle = document.createElement('li')
    let detailsDate = document.createElement('li')

    section.setAttribute('class', 'artwork-section p-3 row')

    columnThumbnail.setAttribute('class', 'col-auto')
    columnDetails.setAttribute('class', 'col')

    thumbnailImage = generateImage(item.b64)
    thumbnailImage.setAttribute('id', item.title + item.dateTime)
    thumbnailImage.width = 100
    thumbnailImage.height = 100
    thumbnailButton.appendChild(thumbnailImage)
    columnThumbnail.appendChild(thumbnailButton)

    details.setAttribute('class', 'list-unstyled')
    detailsTitle.style.textTransform = 'capitalize'
    detailsTitle.innerHTML = item.title
    detailsDate.innerHTML = item.dateTime
    details.appendChild(detailsTitle)
    details.appendChild(detailsDate)
    columnDetails.appendChild(details)

    section.appendChild(columnThumbnail)
    section.appendChild(columnDetails)

    container.appendChild(section)

    thumbnailButton.addEventListener('click', (e) => {
        generatePDF(item.b64, item.title)
    })
}

function generatePDF(b64: string, title: string): void {
    let doc = new jsPDF({
        unit: 'mm',
    })

    doc.addImage(
        {
            imageData: b64,
            format: 'JPG',
            x: 7,
            y: 10,
            width: 102,
            height: 102
        }
    )

    doc.addFileToVFS('NordicNarrow-normal.ttf', NordicNarrow.font);
    doc.addFont('NordicNarrow-normal.ttf', 'NordicNarrow', 'normal');
    doc.setFont('NordicNarrow')
    doc.setFontSize(12)
    doc.text(capitalizeString(title), 17, 127)

    doc.setLineDashPattern([2], 1);
    doc.setLineWidth(0.2);
    doc.line(0, 10, 120, 10);
    doc.line(7, 0, 7, 140);
    doc.line(109, 0, 109, 140);
    doc.line(0, 130, 120, 130);

    doc.save(`${title}.pdf`)
}

function capitalizeString(text: string): string {
    let textArray = text.split(" ");

    for (let i = 0; i < textArray.length; i++) {
        textArray[i] = textArray[i].charAt(0).toUpperCase() + textArray[i].slice(1);
    }

    return textArray.join(" ");
}

function generateImage(b64: string): Image {
    console.log(b64)
    let image = new Image()

    b64 = 'data:image/gif;base64,' + b64
    image.src = b64

    return image
}

function GetURLParameter(): string {
    let urlPara = window.location.search
    let museumCode = urlPara.substring(urlPara.indexOf("=") + 1);

    return museumCode
}

fetch(`https://api.barnasminimuseum.muntimagic.com/${GetURLParameter()}`)
    .then((response) => {
        if (response.status === 200) {
            response.json()
                .then((arr) => {
                    for (const artworkString of arr) {
                        const artworkObj = JSON.parse(artworkString);

                        let artwork: ArtworkItem = {
                            title: artworkObj.Title,
                            dateTime: artworkObj.DateTime,
                            b64: artworkObj.ImageB64
                        }

                        generateList(artwork)
                    }
                })
        }
    })